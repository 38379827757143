@media(min-width:992px){
  .navbar-expand-lg .navbar-nav{
    margin-right: 6rem;
  }
}



.megamenu {
    position: static;
  }
  
  .megamenu .dropdown-menu {
    background: none;
    border: none;
    width: 100%;
  }

.dropdown-menu h6 {
    color: #ffc10e;
}

.black{
    background-color: #000000 !important;
}

  .mega-link{
      padding: 12px 0px !important;
  }
  * {
    box-sizing: border-box;
  }
  
  /* Position the image container (needed to position the left and right arrows) */
  @media (min-width: 992px){
      .dropdown-menu{
          right: 0;
      }
  }
      
  /* mega menu hover */


@media(min-width:1367px){
    .nav-item:hover .dropdown-menu{
        display: block;
    }
}

.dropdown-menu{
    top: 100% !important;
    min-width: 50rem;
    right: -20rem !important;
    padding-top: 1rem !important;
    background-color: transparent !important;
    float: left !important;
    left: auto !important;
}
.nav-link{
    padding: .5rem 1rem !important;
}

@media(max-width:769px){
    .navbar-collapse{
        height: 13rem !important;
        overflow-y: auto;
    }
    .dropdown-menu{
        min-width: 10rem !important;
    }
}
/* opacity text for section headers */
.opacity_text{
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 150px;
    font-weight: 900;
    color: #0a0a0a24;
}

.opacity_text_service{
    position: absolute;
    right: 0px;
    top: 20px;
    font-size: 190px;
    font-weight: 900;
    color: #bababa24;
}

.opacity_text_gallery{
  position: absolute;
  right: 0px;
  top: 35px;
  font-size: 190px;
  font-weight: 600;
  color: #bababa24;
}

.opacity_text_gallery-white{
  position: absolute;
  right: 0px;
  top: 35px;
  font-size: 190px;
  font-weight: 600;
  color: #bababa61;
}

/* about image overflow */
.about_img{
    overflow: hidden;
    margin-bottom: 50px;
    border: solid 3px #ffc10e;

}

.about_img img {
  max-width:550px;
}
/* about part */
.about_part{
   
    position: relative;
    background-image: url(../img/Background-.jpg);
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 100px;
}

.about-2-img{
  border: solid 3px #ffc10e;
}

/* Services Section
--------------------------------*/

#services {
    padding: 150px 0 40px 0;
    background-color: #000000;
    position: relative;
   
  }
  
  #services .box {
    padding: 30px;
    height: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 10px 40px 10px;
    background: #ffc10e;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    text-align: center;
  }
  
  #services .box:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  
  #services .icon {
    margin: 0 auto 25px auto;
    padding-top: 12px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  
  #services .icon i {
    font-size:  50px;
    line-height: 1;
  }
  
  #services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  #services .title a {
    color: #000000;
  }
  
  #services .box:hover .title a {
    color: #000000;
  }
  
  #services .description {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 0;
    text-align: left;
    color: #000000;
  }

  .section-header h1{
      color: #fff;
      font-size: 80px;
      margin-bottom: 50px;
  }
  @media(max-width:769px){
    .section-header h1{
        font-size: 70px;
    }
  }
  @media(max-width:360px){
      .section-header h1{
          font-size: 50px;
      }
  }

  /* About header */

  .about_text h1{
    font-size: 3.5rem;
  }
  /* Clients Section
--------------------------------*/

#clients {
  padding: 70px 0;
}

#clients img {
  max-width: 100%;
  opacity: 0.5;
  transition: 0.3s;
  padding: 15px 0;
}

#clients img:hover {
  opacity: 1;
}

#clients .owl-nav,
#clients .owl-dots {
  margin-top: 5px;
  text-align: center;
}

#clients .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#clients .owl-dot.active {
  background-color: #ffc10e;
}

.clients-header h2{
  position: relative;
  font-size: 2.6rem;
  font-weight: 600;
  margin-bottom: 30px;
}

.clients-header h2:after{
  position: absolute;
  content: "";
  left: 0;
  bottom: 0px;
  width: 100px;
  height: 2px;
  background-color: #ffc10e;
}

.clients-header p {
  margin-bottom: 1.5rem;
}

/* portfolio section */

.controls .filter {
  margin: 0 10px 0px 10px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 25px;
}


#protfolio{
  padding-top: 170px;
  background-color: #000000;
  position: relative;
}
#protfolio-white{
  padding-top: 170px;
  background-color: #ffffff;
  position: relative;
  margin-top: 100px;
}
.gallery-header h1{
  color: #fff;
  font-size: 80px;
   margin-bottom: 50px;
}

.gallery-header-white h1{
  color: #000000;
  font-size: 80px;
   margin-bottom: 50px;
}

@media(max-width:769px){
  .gallery-header h1{
      font-size: 70px;
  }
  .gallery-header-white h1{
    font-size: 70px;
  }
}
@media(max-width:360px){
    .gallery-header h1{
        font-size: 50px;
    }
    .gallery-header-white h1{
      font-size: 50px;
    }
}

.overlay-bg-content p{
  color: #000000 !important;
}

.overlay-bg-content h5{
  color: #000000 !important;
}

.controls{
  margin-bottom: 2rem;
}

.controls .filter:hover {
  color: #fff !important;
}

.controls .filter.active {
  color: #000000 !important;
  background-color: #ffc10e;
}

.single-filter-content {
  height: 350px;
  position: relative;
}

.single-filter-content .overlay-bg-content {
  /*
  background: -moz-linear-gradient(left, rgba(60, 64, 143, 0.95) 0%, rgba(91, 97, 207, 0.95) 100%);
  background: -webkit-linear-gradient(left, rgba(60, 64, 143, 0.95) 0%, rgba(91, 97, 207, 0.95) 100%);
  background: linear-gradient(to right, rgba(60, 64, 143, 0.95) 0%, rgba(91, 97, 207, 0.95) 100%);
  */
  background-color: #ffc10ed4;
  text-align: center;
  padding: 50px 100px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-filter-content .overlay-bg-content p {
  margin: 0;
}

.single-filter-content .overlay-bg-content .line {
  margin: 10px 0 15px 0;
  width: 100%;
  height: 1px;
  background: #fff;
}

.single-filter-content:hover .overlay-bg-content {
  opacity: 1;
}



/* Testimonials Section
--------------------------------*/

#testimonials {
  padding: 4.5rem 0;
}

#testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 0 30px;
  margin: 30px 15px;
  text-align: center;
  margin-bottom: 30px;
}

#testimonials .testimonial-item .testimonial-img {
  width: 200px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
  margin-bottom: 30px;
}

#testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

#testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

#testimonials .testimonial-item .quote-sign-left {
  margin-top: -15px;
  padding-right: 10px;
  display: inline-block;
  width: 37px;
}

#testimonials .testimonial-item .quote-sign-right {
  margin-bottom: -15px;
  padding-left: 10px;
  display: inline-block;
  max-width: 100%;
  width: 37px;
}

#testimonials .testimonial-item p {
  margin: 0 auto 15px auto;
  color: #6f6f6f;
  font-size: 16px;
  line-height: 1.6;
  margin-top: 15px;
}

@media(min-width:1024px){

  #testimonials .testimonial-item p {
    width: 75%;
  }

}

#testimonials .owl-nav,
#testimonials .owl-dots {
  margin-top: 5px;
  text-align: center;
}

#testimonials .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#testimonials .owl-dot.active {
  background-color: #ffc10e;
}

.testmonials-header h2{
  position: relative;
  font-size: 2.6rem;
  font-weight: 600;
  margin-bottom: 30px;
}

.testmonials-header h2:after{
  position: absolute;
  content: "";
  left: 0;
  bottom: 0px;
  width: 230px;
  height: 2px;
  background-color: #ffc10e;
}

.flaticon-left-quote:before{
  color: #ffc10e;
  font-size: xx-large !important;
}

.flaticon-right-quote-sign:before{
  color: #ffc10e;
  font-size: xx-large !important;
}

/* parallax footer */
@media(min-width:767px){
main{
  z-index: 5;
  position: relative;
  background-color: #fff;
  margin-bottom: 40rem;
}
footer{
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: -1;
  padding-top: 0px !important;
  margin-top: 0px !important;
  background-color: #000000 !important;
}
}

footer{

  background-color: #000000 !important;
}
footer p {
  color: #fff;
}
footer a{
  color: #fff;
}
.section-footer{
  background-color: #000000 !important;
}

.footer-logo{
  margin-bottom: 1rem;
  margin-top: -2.5rem;
}

.footer-logo img{
  width: 60%;
  float: left;
}

.footer-clist i{
  margin-right: 1em;
  font-size: 1.2em ;
}

.footer-clist li{
  float: left;
}

.fot-row{
  margin-left: 7rem;
}

@media(max-width:1024px){
  .fot-row{
    margin-left: 3rem;
  }
  .footer-logo img{
    width: 70%;
    float: left;
  }
}

/*
.underline{
  position: absolute;
    right: 0px;
    top: -80px;
    font-size: 35px !important;
    font-weight: 400 !important;
    color: #bababa24 !important;
}
*/


.underline::after{
  content: '';
  background-color: #FFC10E;
  position: absolute;
  bottom: 60px;
  left: 150px;
  width: 17rem;
  height: 3px;
}

@media(max-width:767px){
  .underline::after{
    content: '';
    background-color: #FFC10E;
    position: absolute;
    bottom: 36px;
    left: 0px;
    width: 7rem;
    height: 3px;
  }
}



.site-map .row{
  margin-left: 0 !important;
  margin-right: 0 !important;
}


.section-footer .widget-a .w-title-a {
  margin-bottom: 3rem !important;
  font-weight: 400;
  
}


/* Team Section */

.section-agents{
  padding-bottom: 8rem;
}

/* Contact Page */

.contact{
  padding-bottom: 8rem;
}

/* floating labels */

/*
.floating {
  margin-bottom: 2rem;
  background-color: var(--field__background);
  transition: background-color 0.2s ease;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.floating:hover,
.floating:focus-within {
  background-color: var(--field__background--hover);
}

.floating__input {
  padding: 1.8rem 1rem 0.6rem;
  font-size: 1rem;
  border-bottom: 0.1rem solid var(--input__border);
  transition: border-color 0.2s ease;
  caret-color: var(--color__accent);
}

.floating:hover .floating__input {
  border-color: var(--input__border--hover);
}

.floating__input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.floating__label {
  display: block;
  position: relative;
  max-height: 0;
  font-weight: 500;
  pointer-events: none;
}

.floating__label::before {
  color: var(--label__color);
  content: attr(data-content);
  display: inline-block;
  filter: blur(0);
  backface-visibility: hidden;
  transform-origin: left top;
  transition: transform 0.2s ease;
  left: 1rem;
  position: relative;
}

.floating__label::after {
  bottom: 1rem;
  content: "";
  height: 0.1rem;
  position: absolute;
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
  opacity: 0;
  left: 0;
  top: 100%;
  margin-top: -0.1rem;
  transform: scale3d(0, 1, 1);
  width: 100%;
  background-color: var(--color__accent);
}

.floating__input:focus + .floating__label::after {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.floating__input:placeholder-shown + .floating__label::before {
  transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
}

.floating__label::before,
.floating__input:focus + .floating__label::before {
  transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
}

.floating__input:focus + .floating__label::before {
  color: var(--color__accent);
}

/* Navbar brand */

.navbar-brand img{
  width: 70%;
}

.navbar-brand{
  width: 20rem;
}

@media(max-width:1200px){
  .navbar-brand{
    width: 16rem;
  }
}

@media(max-width:355px){
  .navbar-brand{
    width: 13rem;
  }
}


.input-group-text{
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
  border: none;
  color: #c9ccce;
  font-weight: 300;
  border-radius: 0;
  height: 100%;
}

.input-group .form-control{
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.input-group{
  border: 1px solid #eaeff4;
  border-radius: 2px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.sidebar-title{
  margin-bottom: 1rem;
}

.sidebar{
  margin-bottom: 3rem;
}


/* post owl */

#post-owl .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#post-owl .owl-dot.active {
  background-color: #ffc10e;
}

#post-owl .owl-nav,
#post-owl .owl-dots {
  margin-top: 5px;
  text-align: center;
}

#post-owl .owl-carousel .owl-stage {
  display: flex !important;
}

#post-owl .owl-carousel .owl-item img {
  width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: initial;
}


.addthis_inline_share_toolbox{
  text-align: center;
}

/* custom scroll-bar */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	/* border-radius: 10px; */
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  /* border-radius: 20px; */
  border: 6px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(54, 54, 54);
}